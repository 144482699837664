import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  styles,
  Heading,
  Padding,
  PinkDelimiter,
  Text,
  StrongText,
} from "../common/styles"
import styled from "@emotion/styled"
import { StaticQuery, graphql } from "gatsby"
import _ from "lodash"
import Img from "gatsby-image/withIEPolyfill"
import { media } from "../common/mediaQueries"
import parse from "html-react-parser"
import { Button } from "../components/button"

const Referenzen = () => {
  return (
    <Layout>
      <SEO
        title="Referenzen"
        keywords={["Referenzen", "Projekte", "Patricia on Ice"]}
      />
      <Wrapper>
        <Padding value="2vw" axis="y" />
        <Heading color={styles.colors.blue.dark}>Referenzen</Heading>
        <Padding value="1vw" axis="y" />
        <PinkDelimiter />
        <StaticQuery
          query={graphql`
            {
              allWordpressPost(
                filter: {
                  categories: { elemMatch: { name: { eq: "Referenzen" } } }
                }
              ) {
                edges {
                  node {
                    title
                    excerpt
                    slug
                    date
                    featured_media {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 540, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            return (
              <PostWrapper>
                {_.map(
                  _.reverse(
                    _.sortBy(data.allWordpressPost.edges, ["node.date"])
                  ),
                  (edge, idx) => {
                    const post = edge.node
                    return (
                      <Post key={idx}>
                        <ImageWrapper
                          children={
                            _.get(
                              post,
                              "featured_media.localFile.childImageSharp.fluid"
                            ) && (
                              <Img
                                alt="Patricia On Ice - Foto"
                                object-fit='cover'
                                objectPosition="50% 0%"
                                fluid={_.get(
                                  post,
                                  "featured_media.localFile.childImageSharp.fluid"
                                )}
                              />
                            )
                          }
                        />
                        <TextContainer>
                          <StrongText color="blue" children={post.title} />
                          <Padding value="1vw" axis="y" />
                          <PinkDelimiter />
                          <Padding value="1vw" axis="y" />
                          <Text children={parse(post.excerpt)} />
                          <div style={{ textAlign: "center" }}>
                            <Button
                              content="Weiterlesen"
                              linkTo={`post/${post.slug}`}
                              look="secondary"
                            />
                          </div>
                        </TextContainer>
                      </Post>
                    )
                  }
                )}
              </PostWrapper>
            )
          }}
        />
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`

const PostWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  align-content: stretch;
  width: 80vw;
  margin: 0 auto;
`

const Post = styled.div`
  width: calc(79.99vw / 3);
  background-color: ${styles.colors.lightGrey};
  border: 2vw solid white;
  display: flex;
  flex-direction: column;
  ${media.mobile} {
    flex: 0 0 100%;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  flex: 0 0 15vw;
  overflow: hidden;
  background-color: ${styles.colors.violet};
  * {
    height: 100%;
  }
  ${media.mobile} {
    flex: 0 0 40vw;
  }
  ${media.wideDesktop} {
    flex: 0 0 7vw;
  }
`
const TextContainer = styled.div`
  padding: 1.5vw;
`

export default Referenzen
